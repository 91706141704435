<template>
    <div class="main main-architecture Searchs">
        <div class="content">
            <div class="bread-crumbs">
                <div class="pw">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item>高级搜索</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
            <div class="ctrl-panel-list">
                <form class="search-form-advanced">
                    <Search :text="keyword" :texts="select" @childFn="keywordFn" @chouse="chouses"></Search>
                </form>
                <div class="clearfix">
                    <dl class="fl tags">
                        <dt>
                            热门：
                        </dt>
                        <dd>
                            <span v-for="(item,index) in hotList" :key="index" @click="Topsearch(item)">{{item.name}}</span>
                        </dd>
                    </dl>
                </div>
            </div>
            <div class="ctrl-panel-list clearfix" >
                <a class="Mormore" @click="hidess()">
                    <span>{{shows==true?'更多':'收起'}}</span>
                    <i :class="shows==true?'el-icon-arrow-down':'el-icon-arrow-up'"></i>
                </a>
                <div :class="shows==true?'hide':'hides'">
                    
                    <dl class="filter-items checkbox-mode" v-for="(item,index) in ficaList" :key="index">
                        <dt>
                            {{item.type}}:
                        </dt>
                        <dd class="primary">
                            <a v-for="(items,indexs) in item.tag" :key="indexs" class="aft-item" :class="items.state==1?'selected':''" @click="choos(items,index)">
                                <span>
                                    {{items.name}}
                                </span>
                            </a>
                        </dd>
                    </dl>
                </div>
            </div>
            <!-- PPT -->
            <div class="articles-list" style="max-width:100%" v-show="this.select==1&&artiList.length!=0">
                <article class="post" v-for="(item,index) in artiList" :key="'info'+index">
                    <div class="inner" @click="jump(1,item)">
                        <div class="media-pic">
                            <i class="tag-vip"></i>
                            <img :src="item.cover" width="600" height="400" alt="" class="wp-post-image">
                            <a class="post-cate">
                                <em class="tag-213">
                                    演示版
                                </em>
                            </a>
                        </div>
                        <div class="clearfix">
                            <p class="post-date fl">
                                <span class="" v-for="(citem,cindex) in item.tag.split(',')" :key="cindex">
                                    <span class="mix_back_span"  >
                                        {{citem}}
                                    </span>
                                </span>
                            </p>
                        </div>
                        <div class="media-body clearfix">
                            <a class="title fl" :title="item.name">
                                <span class="title_span" v-show="item.animation!=0">{{item.animation==1?'路演':item.animation==2?'网评':''}}</span>
                                {{item.name}}
                            </a>
                            <p class="post-meta fr">
                                <em>
                                    <i class="iconfont iconai-eye"></i>{{item.pv}}
                                </em>
                                <em>
                                    <i class="iconfont iconaixin"></i>{{item.laud}}
                                </em>
                            </p>
                        </div>
                    </div>
                </article>
            </div>
            <!-- WORD -->
            <div class="articles-list" style="max-width:100%" v-show="this.select==2&&artiList.length!=0">
                <article class="post posts" v-for="(item,index) in artiList" :key="index">
                    <div class="wode_iner" @click="jump(2,item)">
                        <i class="tag-vip"></i>
                        <img :src="item.cover" alt="">
                        <a class="post-cate">
                            <em class="tag-213">
                                文档版
                            </em>
                        </a>
                        <div class="flex-ccj">
                            <a class="">{{item.name}}</a>
                            <div class="flex-ca clearfix">
                                <span class="fl" v-for="(citem,cindex) in item.tag.split(',')" :key="cindex">
                                    <span class="mix_back_span"  >
                                        {{citem}}
                                    </span>
                                </span>
                            </div>
                            <div class="flex_div fl">
                                <em>
                                    <i class="iconfont iconai-eye"></i>{{item.pv}}
                                </em>
                                <em>
                                    <i class="iconfont iconaixin"></i>{{item.laud}}
                                </em>
                            </div>
                            <div class="mix_back">

                            </div>
                        </div>
                    </div>
                </article>
            </div>
            <div class="nodata" v-show="artiList.length==0">
                    <img src="./../../assets/stw049.png" alt="">
                </div>
        </div>
        <!-- 分页 -->
            <div class="pagination" v-show="artiList.length!=0">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="30"
                    prev-text="上一页"
                    next-text="下一页"
                    background
                    layout="prev, pager, next"
                    :total="totalnum">
                </el-pagination>
            </div>
    </div>
</template>
<script>
import Search from '@/components/search/search.vue';
export default {
    components:{
        Search: Search
    },
    data() {
        return {
            shows:true,
            select:'',
            currnum:1,
            istruenum:-1,
            ficaList:[],
            istrue:0,
            artiList:[],
            wordList:12,
            keyword:'',
            hotList:[],
            select:0,
            currentPage:1,
            totalnum:0,
            tag1:0,
            tag2:0,
            tag3:0,
            tag4:0,
            tag5:0
        }
    },
    methods: {
        getapp(){
            this.keyword = this.$route.query.keyword
            this.axios.searchkey({
                token:this.$store.token,
                keyword:this.keyword,
                sort:this.currnum==1?'default':this.currnum==2?'time':'download',
                type:this.select,
                tag1:this.tag1,
                tag2:this.tag2,
                tag3:this.tag3,
                tag4:this.tag4,
                tag5:this.tag5,
                pageNumber:1,
            }).then(res=>{
                if(res.data.code==0){
                    this.artiList = res.data.data.rows
                    this.totalnum = res.data.data.total
                }else{
                    this.artiList = []
                }
            }).catch(err=>{

            })
        },
        // 目录更多收起
        hidess(){
            if(this.shows==true){
                this.shows= false
            }else{
                this.shows= true
            }
        },
        // 查询目录
        directory(){
            this.axios.catalog({
                sort:this.currnum==1?'default':this.currnum==2?'time':'download',
                type:this.select,
                tag1:this.tag1,
                tag2:this.tag2,
                tag3:this.tag3,
                tag4:this.tag4,
                tag5:this.tag5
            }).then(res=>{
                // console.log(this.hotList);
                if(res.data.code==0){
                    this.ficaList = res.data.data
                }else{
                    this.ficaList = []
                }
            }).catch(err=>{
                console.log(err)
            })
            // 热门搜索词
            this.axios.searchtop({
                params:{

                }
            }).then(res=>{
                if(res.data.code==0){
                    this.hotList = res.data.data
                }else{
                    this.hotList = []
                }
            }).catch(err=>{
                console.log(err)
            })
        },
        // 综合热门时间排序
        curr(val){
            this.currnum = val
        },
        chooses(){
            this.istruenum = -1
        },
        choos(item,index){
            this.currentPage = 1
            if(index==0){
                this.currnum = item.id
            }else if(index==1){
                this.tag2 = item.id
            }else if(index==2){
                this.tag1 = item.id
            }else if(index==3){
                this.tag3 = item.id
            }else if(index==4){
                this.tag4 = item.id
            }else if(index==5){
                this.tag5 = item.id
            }
            this.getapp()
            this.directory()
        },
        jump(val,item){
            let link = this.$router.resolve({name:'Detailspage',query:{type:val,code:item.code}})
            window.open(link.href,'_blank')
        },
        choose(item,index){
            this.ficaList[index].state = item.id
            this.istruenum = 1
        },
        //热门搜索
        Topsearch(item){
            this.keywordFn(item.name,this.select)
        },
        chouses(item){
            this.keywordFns(item)
        },
        keywordFns(val){
            this.select = val
        },
        keywordFn(key,val){
            this.keyword = key
            this.select = val
        },
        //分页
        handleSizeChange(val){
           
        },
        handleCurrentChange(val){
            document.documentElement.scrollTop = 0;  //ie下
            document.body.scrollTop = 0;  //非ie
            this.currentPage = val;
            this.axios.searchkey({
                token:this.$store.token,
                keyword:this.keyword,
                sort:this.currnum==1?'default':this.currnum==2?'download':'time',
                type:this.select,
                tag1:this.tag1,
                tag2:this.tag2,
                tag3:this.tag3,
                tag4:this.tag4,
                tag5:this.tag5,
                pageNumber:val
            }).then(res=>{
                if(res.data.code==0){
                    this.artiList = res.data.data.rows
                    this.totalnum = res.data.data.total
                }else{
                    this.artiList = []
                }
            }).catch(err=>{

            })
        }
    },
    mounted() {
        this.select= this.$route.query.select
        this.getapp()
        this.directory()
    }
}
</script>
<style scoped>
@import './../../styles/Page/SearchPage.css';
</style>
<style>
.bread-crumbs .el-breadcrumb{
    line-height: 40px;
}
.search-form-advanced .m-search-box .el-input__inner{
    /* background-color: #f8f8f8; */
    color: inherit;
}
.search-form-advanced  .m-search-box .el-input-group__append{
    color: #8e92a2;
}
.search-form-advanced .el-input-group__prepend div.el-select .el-input__inner{
    color: #6b8494;
}
.pagination .el-pagination.is-background .el-pager li:not(.disabled).active{
    border: 1px solid #58abe0;
    background: rgba(88,171,224,.05);
    color: #58abe0;
}
.el-pagination button{
    height: 32px;
    line-height: 32px;
}
.el-pagination button, .el-pagination span:not([class*=suffix]){
    height: 32px;
    line-height: 32px;
}
.pagination .el-pagination .btn-prev{
    padding: 0 15px;
    border: 1px solid var(--baseBorderColor,#ebebeb);
    color: var(--fc,#6b8494);
    min-width: 32px!important;
}
.pagination .el-pagination .btn-next{
    padding: 0 15px;
    border: 1px solid var(--baseBorderColor,#ebebeb);
    color: var(--fc,#6b8494);
    min-width: 32px!important;
}
.pagination .el-pager li{
    padding: 0;
    font-size: 13px;
    min-width: 32px!important;
    height: 32px;
    line-height: 32px;
    box-sizing: border-box;
    text-align: center;
}
.pagination .el-pagination.is-background .btn-prev,.pagination .el-pagination.is-background .btn-next, .pagination .el-pagination.is-background .el-pager li{
    background: transparent;
    border: 1px solid var(--baseBorderColor,#ebebeb);
    color: var(--fc,#6b8494);
}
/* .pagination .el-pagination button:disabled{
    display: none;
} */
</style>