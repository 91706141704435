<template>
    <div class="m-search-box">
        <el-input placeholder="请输入搜索关键词" v-model="keyword" type="text" class="input-with-select" @input="input" >
            <el-select v-model="select" slot="prepend" placeholder="请选择" @change="chose(select)">
                <el-option label="BP演示版" value="1"></el-option>
                <el-option label="BP文档版" value="2"></el-option>
            </el-select>
            <el-button slot="append" @click="search()" icon="el-icon-search"></el-button>
        </el-input>
    </div>
</template>
<script>
export default {
    props: ['text','texts'],
    data() {
        return {
            select:'1',
            keyword:'',
        }
    },
    methods: {
        getapp(){
        },
        input(){
            this.$emit('childFn', this.keyword,this.select)
        },
        chose(val){
            this.$emit('chouse',val)
            if(this.$route.name=='SearchPage'){
                this.$router.push({name:'SearchPage',query:{
                    select:val
                }})
            }else{
                return false
            }
            
        },
        search(){
            this.$emit('childFn', this.keyword,this.select)
                this.$router.push({name:'SearchPage',query:{
                    keyword:this.keyword,
                    select:this.select
                }})
            
        },
        Topsearch(item){
            this.$emit('childFn', item.name)
            let routeUrl = this.$router.resolve({name:'SearchPage',query:{
                keyword:item.name
            }})
            window.open(routeUrl.href,'_blank')
        }
    },
    watch:{
        text(val){
            const _that = this
            _that.keyword = val
        },
        texts(val){
            const _that = this
            _that.select = val
        }
    },
    mounted() {
        this.getapp()
    }
}
</script>
<style>
.search_ul{
    position: absolute;
    height: 24px;
    line-height: 24px;
    bottom: -32px;
    left: 0;
}
.search_ul li{
    height: 24px;
    line-height: 24px;
    padding: 0;
    margin-right: 10px;
    position: relative;
    float: left;
    display: block;
}
.label::before{
    display: none;
}
.search_ul li span{
    color: #fff;
    position: relative;
    z-index: 1;
    height: 24px;
    line-height: 24px;
    margin-right: 0;
    padding: 0 8px;
    float: left;
    display: block;
    cursor: pointer;
}
.search_ul li::before{
    content: "";
    background: #000;
    border-radius: 4px;
    opacity: .2;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.clearfix:after{
    content: ""; 
    display: block; 
    height: 0; 
    clear: both; 
    visibility: hidden;  
}
.clearfix { 
    zoom: 1; 
}
.label{
    color: #fff;
}
.m-search-box{
    width: 100%;
    height:40px;
    border-radius: 4px;
    box-sizing: border-box;
    /* background-color: #fff; */
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 123;
    /* border: 2px solid #eb4e2f; */
    border-right: 0;
}
.m-search-box .el-input__inner{
    width: 100%;
    border: 0 none;
    position: relative;
    box-sizing: border-box;
    padding: 0 40px 0 15px;
    height: 40px;
    z-index: 1;
    font-size: 14px;
    background-color: transparent;
    color: #b1b7bd;
}
.m-search-box .el-input-group{
    height: 100%;
    position: relative;
}
.m-search-box .el-button{
    border: 0;
    height: 40px;
    width: 100%;
    /* background-color: #eb4e2f!important; */
    border-radius: 0;
    border-radius: 0 4px 4px 0px;
    text-align: center;
    color: #b1b7bd;
    cursor: pointer;
    margin: 0;
}
.m-search-box .el-button i{
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
}
.m-search-box .el-input-group__prepend{
    border: 0;
    background: transparent;
    padding: 0 0px;
    box-sizing: border-box;
}
.m-search-box .el-input-group__append {
    border: 0;
    color: #b1b7bd;
    padding: 0;
    text-align: center;
    background: none;
    width: 40px;
    height: 40px;
    padding-right: 10px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
}
.m-search-box .el-input__icon{
    width: auto;
    line-height: 36px;
}
.m-search-box  .el-select .el-input__inner{
    width:100%;
    padding: 0 10px 0 10px;
    border-right: 1px;
}
.m-search-box  .el-input__suffix{
    right: 15px;
}
/* .m-search-box  .el-select .el-input{
    padding-left: 14px;
} */
.m-search-box  .el-select{
    width: 110px;
    background: transparent;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 4px;
    height: 37px;
    line-height: 37px;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 0;
}
</style>